.cf-16{display:inline-block;vertical-align:middle}
.cf-16:before{content:"";display:block;background:no-repeat 50% 50%;background-size:contain;width:16px;height:16px}
.cf-16.cf-af:before{background-image:url(flat16/af.png)}
.cf-16.cf-al:before{background-image:url(flat16/al.png)}
.cf-16.cf-dz:before{background-image:url(flat16/dz.png)}
.cf-16.cf-as:before{background-image:url(flat16/as.png)}
.cf-16.cf-ad:before{background-image:url(flat16/ad.png)}
.cf-16.cf-ao:before{background-image:url(flat16/ao.png)}
.cf-16.cf-ai:before{background-image:url(flat16/ai.png)}
.cf-16.cf-aq:before{background-image:url(flat16/aq.png)}
.cf-16.cf-ag:before{background-image:url(flat16/ag.png)}
.cf-16.cf-ar:before{background-image:url(flat16/ar.png)}
.cf-16.cf-am:before{background-image:url(flat16/am.png)}
.cf-16.cf-aw:before{background-image:url(flat16/aw.png)}
.cf-16.cf-au:before{background-image:url(flat16/au.png)}
.cf-16.cf-at:before{background-image:url(flat16/at.png)}
.cf-16.cf-az:before{background-image:url(flat16/az.png)}
.cf-16.cf-bs:before{background-image:url(flat16/bs.png)}
.cf-16.cf-bh:before{background-image:url(flat16/bh.png)}
.cf-16.cf-bd:before{background-image:url(flat16/bd.png)}
.cf-16.cf-bb:before{background-image:url(flat16/bb.png)}
.cf-16.cf-by:before{background-image:url(flat16/by.png)}
.cf-16.cf-be:before{background-image:url(flat16/be.png)}
.cf-16.cf-bz:before{background-image:url(flat16/bz.png)}
.cf-16.cf-bj:before{background-image:url(flat16/bj.png)}
.cf-16.cf-bm:before{background-image:url(flat16/bm.png)}
.cf-16.cf-bt:before{background-image:url(flat16/bt.png)}
.cf-16.cf-bo:before{background-image:url(flat16/bo.png)}
.cf-16.cf-ba:before{background-image:url(flat16/ba.png)}
.cf-16.cf-bw:before{background-image:url(flat16/bw.png)}
.cf-16.cf-br:before{background-image:url(flat16/br.png)}
.cf-16.cf-vg:before{background-image:url(flat16/vg.png)}
.cf-16.cf-bn:before{background-image:url(flat16/bn.png)}
.cf-16.cf-bg:before{background-image:url(flat16/bg.png)}
.cf-16.cf-bf:before{background-image:url(flat16/bf.png)}
.cf-16.cf-bi:before{background-image:url(flat16/bi.png)}
.cf-16.cf-kh:before{background-image:url(flat16/kh.png)}
.cf-16.cf-cm:before{background-image:url(flat16/cm.png)}
.cf-16.cf-ca:before{background-image:url(flat16/ca.png)}
.cf-16.cf-cv:before{background-image:url(flat16/cv.png)}
.cf-16.cf-ky:before{background-image:url(flat16/ky.png)}
.cf-16.cf-cf:before{background-image:url(flat16/cf.png)}
.cf-16.cf-td:before{background-image:url(flat16/td.png)}
.cf-16.cf-cl:before{background-image:url(flat16/cl.png)}
.cf-16.cf-cn:before{background-image:url(flat16/cn.png)}
.cf-16.cf-cx:before{background-image:url(flat16/cx.png)}
.cf-16.cf-co:before{background-image:url(flat16/co.png)}
.cf-16.cf-km:before{background-image:url(flat16/km.png)}
.cf-16.cf-ck:before{background-image:url(flat16/ck.png)}
.cf-16.cf-cr:before{background-image:url(flat16/cr.png)}
.cf-16.cf-hr:before{background-image:url(flat16/hr.png)}
.cf-16.cf-cu:before{background-image:url(flat16/cu.png)}
.cf-16.cf-cy:before{background-image:url(flat16/cy.png)}
.cf-16.cf-cz:before{background-image:url(flat16/cz.png)}
.cf-16.cf-cd:before{background-image:url(flat16/cd.png)}
.cf-16.cf-dk:before{background-image:url(flat16/dk.png)}
.cf-16.cf-dj:before{background-image:url(flat16/dj.png)}
.cf-16.cf-dm:before{background-image:url(flat16/dm.png)}
.cf-16.cf-do:before{background-image:url(flat16/do.png)}
.cf-16.cf-ec:before{background-image:url(flat16/ec.png)}
.cf-16.cf-eg:before{background-image:url(flat16/eg.png)}
.cf-16.cf-sv:before{background-image:url(flat16/sv.png)}
.cf-16.cf-gq:before{background-image:url(flat16/gq.png)}
.cf-16.cf-er:before{background-image:url(flat16/er.png)}
.cf-16.cf-ee:before{background-image:url(flat16/ee.png)}
.cf-16.cf-et:before{background-image:url(flat16/et.png)}
.cf-16.cf-fk:before{background-image:url(flat16/fk.png)}
.cf-16.cf-fj:before{background-image:url(flat16/fj.png)}
.cf-16.cf-fi:before{background-image:url(flat16/fi.png)}
.cf-16.cf-fr:before{background-image:url(flat16/fr.png)}
.cf-16.cf-pf:before{background-image:url(flat16/pf.png)}
.cf-16.cf-ga:before{background-image:url(flat16/ga.png)}
.cf-16.cf-gm:before{background-image:url(flat16/gm.png)}
.cf-16.cf-ge:before{background-image:url(flat16/ge.png)}
.cf-16.cf-de:before{background-image:url(flat16/de.png)}
.cf-16.cf-gh:before{background-image:url(flat16/gh.png)}
.cf-16.cf-gi:before{background-image:url(flat16/gi.png)}
.cf-16.cf-gr:before{background-image:url(flat16/gr.png)}
.cf-16.cf-gl:before{background-image:url(flat16/gl.png)}
.cf-16.cf-gd:before{background-image:url(flat16/gd.png)}
.cf-16.cf-gu:before{background-image:url(flat16/gu.png)}
.cf-16.cf-gt:before{background-image:url(flat16/gt.png)}
.cf-16.cf-gg:before{background-image:url(flat16/gg.png)}
.cf-16.cf-gn:before{background-image:url(flat16/gn.png)}
.cf-16.cf-gy:before{background-image:url(flat16/gy.png)}
.cf-16.cf-ht:before{background-image:url(flat16/ht.png)}
.cf-16.cf-hn:before{background-image:url(flat16/hn.png)}
.cf-16.cf-hk:before{background-image:url(flat16/hk.png)}
.cf-16.cf-hu:before{background-image:url(flat16/hu.png)}
.cf-16.cf-is:before{background-image:url(flat16/is.png)}
.cf-16.cf-in:before{background-image:url(flat16/in.png)}
.cf-16.cf-id:before{background-image:url(flat16/id.png)}
.cf-16.cf-ir:before{background-image:url(flat16/ir.png)}
.cf-16.cf-iq:before{background-image:url(flat16/iq.png)}
.cf-16.cf-ie:before{background-image:url(flat16/ie.png)}
.cf-16.cf-im:before{background-image:url(flat16/im.png)}
.cf-16.cf-il:before{background-image:url(flat16/il.png)}
.cf-16.cf-it:before{background-image:url(flat16/it.png)}
.cf-16.cf-jm:before{background-image:url(flat16/jm.png)}
.cf-16.cf-jp:before{background-image:url(flat16/jp.png)}
.cf-16.cf-je:before{background-image:url(flat16/je.png)}
.cf-16.cf-jo:before{background-image:url(flat16/jo.png)}
.cf-16.cf-kz:before{background-image:url(flat16/kz.png)}
.cf-16.cf-ke:before{background-image:url(flat16/ke.png)}
.cf-16.cf-ki:before{background-image:url(flat16/ki.png)}
.cf-16.cf-xk:before{background-image:url(flat16/xk.png)}
.cf-16.cf-kw:before{background-image:url(flat16/kw.png)}
.cf-16.cf-kg:before{background-image:url(flat16/kg.png)}
.cf-16.cf-la:before{background-image:url(flat16/la.png)}
.cf-16.cf-lv:before{background-image:url(flat16/lv.png)}
.cf-16.cf-lb:before{background-image:url(flat16/lb.png)}
.cf-16.cf-ls:before{background-image:url(flat16/ls.png)}
.cf-16.cf-lr:before{background-image:url(flat16/lr.png)}
.cf-16.cf-ly:before{background-image:url(flat16/ly.png)}
.cf-16.cf-li:before{background-image:url(flat16/li.png)}
.cf-16.cf-lt:before{background-image:url(flat16/lt.png)}
.cf-16.cf-lu:before{background-image:url(flat16/lu.png)}
.cf-16.cf-mo:before{background-image:url(flat16/mo.png)}
.cf-16.cf-mk:before{background-image:url(flat16/mk.png)}
.cf-16.cf-mg:before{background-image:url(flat16/mg.png)}
.cf-16.cf-mw:before{background-image:url(flat16/mw.png)}
.cf-16.cf-my:before{background-image:url(flat16/my.png)}
.cf-16.cf-mv:before{background-image:url(flat16/mv.png)}
.cf-16.cf-ml:before{background-image:url(flat16/ml.png)}
.cf-16.cf-mt:before{background-image:url(flat16/mt.png)}
.cf-16.cf-mh:before{background-image:url(flat16/mh.png)}
.cf-16.cf-mq:before{background-image:url(flat16/mq.png)}
.cf-16.cf-mr:before{background-image:url(flat16/mr.png)}
.cf-16.cf-mu:before{background-image:url(flat16/mu.png)}
.cf-16.cf-yt:before{background-image:url(flat16/yt.png)}
.cf-16.cf-mx:before{background-image:url(flat16/mx.png)}
.cf-16.cf-fm:before{background-image:url(flat16/fm.png)}
.cf-16.cf-md:before{background-image:url(flat16/md.png)}
.cf-16.cf-mc:before{background-image:url(flat16/mc.png)}
.cf-16.cf-mn:before{background-image:url(flat16/mn.png)}
.cf-16.cf-me:before{background-image:url(flat16/me.png)}
.cf-16.cf-ms:before{background-image:url(flat16/ms.png)}
.cf-16.cf-ma:before{background-image:url(flat16/ma.png)}
.cf-16.cf-mz:before{background-image:url(flat16/mz.png)}
.cf-16.cf-mm:before{background-image:url(flat16/mm.png)}
.cf-16.cf-na:before{background-image:url(flat16/na.png)}
.cf-16.cf-nr:before{background-image:url(flat16/nr.png)}
.cf-16.cf-np:before{background-image:url(flat16/np.png)}
.cf-16.cf-nl:before{background-image:url(flat16/nl.png)}
.cf-16.cf-nc:before{background-image:url(flat16/nc.png)}
.cf-16.cf-nz:before{background-image:url(flat16/nz.png)}
.cf-16.cf-ni:before{background-image:url(flat16/ni.png)}
.cf-16.cf-ne:before{background-image:url(flat16/ne.png)}
.cf-16.cf-ng:before{background-image:url(flat16/ng.png)}
.cf-16.cf-nu:before{background-image:url(flat16/nu.png)}
.cf-16.cf-nf:before{background-image:url(flat16/nf.png)}
.cf-16.cf-kp:before{background-image:url(flat16/kp.png)}
.cf-16.cf-mp:before{background-image:url(flat16/mp.png)}
.cf-16.cf-no:before{background-image:url(flat16/no.png)}
.cf-16.cf-om:before{background-image:url(flat16/om.png)}
.cf-16.cf-pk:before{background-image:url(flat16/pk.png)}
.cf-16.cf-pw:before{background-image:url(flat16/pw.png)}
.cf-16.cf-ps:before{background-image:url(flat16/ps.png)}
.cf-16.cf-pa:before{background-image:url(flat16/pa.png)}
.cf-16.cf-pg:before{background-image:url(flat16/pg.png)}
.cf-16.cf-py:before{background-image:url(flat16/py.png)}
.cf-16.cf-pe:before{background-image:url(flat16/pe.png)}
.cf-16.cf-ph:before{background-image:url(flat16/ph.png)}
.cf-16.cf-pn:before{background-image:url(flat16/pn.png)}
.cf-16.cf-pl:before{background-image:url(flat16/pl.png)}
.cf-16.cf-pt:before{background-image:url(flat16/pt.png)}
.cf-16.cf-pr:before{background-image:url(flat16/pr.png)}
.cf-16.cf-qa:before{background-image:url(flat16/qa.png)}
.cf-16.cf-cg:before{background-image:url(flat16/cg.png)}
.cf-16.cf-ro:before{background-image:url(flat16/ro.png)}
.cf-16.cf-ru:before{background-image:url(flat16/ru.png)}
.cf-16.cf-rw:before{background-image:url(flat16/rw.png)}
.cf-16.cf-kn:before{background-image:url(flat16/kn.png)}
.cf-16.cf-lc:before{background-image:url(flat16/lc.png)}
.cf-16.cf-mf:before{background-image:url(flat16/mf.png)}
.cf-16.cf-vc:before{background-image:url(flat16/vc.png)}
.cf-16.cf-ws:before{background-image:url(flat16/ws.png)}
.cf-16.cf-sm:before{background-image:url(flat16/sm.png)}
.cf-16.cf-sa:before{background-image:url(flat16/sa.png)}
.cf-16.cf-sn:before{background-image:url(flat16/sn.png)}
.cf-16.cf-rs:before{background-image:url(flat16/rs.png)}
.cf-16.cf-sc:before{background-image:url(flat16/sc.png)}
.cf-16.cf-sl:before{background-image:url(flat16/sl.png)}
.cf-16.cf-sg:before{background-image:url(flat16/sg.png)}
.cf-16.cf-sk:before{background-image:url(flat16/sk.png)}
.cf-16.cf-si:before{background-image:url(flat16/si.png)}
.cf-16.cf-sb:before{background-image:url(flat16/sb.png)}
.cf-16.cf-so:before{background-image:url(flat16/so.png)}
.cf-16.cf-za:before{background-image:url(flat16/za.png)}
.cf-16.cf-gs:before{background-image:url(flat16/gs.png)}
.cf-16.cf-kr:before{background-image:url(flat16/kr.png)}
.cf-16.cf-ss:before{background-image:url(flat16/ss.png)}
.cf-16.cf-es:before{background-image:url(flat16/es.png)}
.cf-16.cf-lk:before{background-image:url(flat16/lk.png)}
.cf-16.cf-sd:before{background-image:url(flat16/sd.png)}
.cf-16.cf-sr:before{background-image:url(flat16/sr.png)}
.cf-16.cf-sz:before{background-image:url(flat16/sz.png)}
.cf-16.cf-se:before{background-image:url(flat16/se.png)}
.cf-16.cf-ch:before{background-image:url(flat16/ch.png)}
.cf-16.cf-sy:before{background-image:url(flat16/sy.png)}
.cf-16.cf-tw:before{background-image:url(flat16/tw.png)}
.cf-16.cf-tj:before{background-image:url(flat16/tj.png)}
.cf-16.cf-tz:before{background-image:url(flat16/tz.png)}
.cf-16.cf-th:before{background-image:url(flat16/th.png)}
.cf-16.cf-tg:before{background-image:url(flat16/tg.png)}
.cf-16.cf-tk:before{background-image:url(flat16/tk.png)}
.cf-16.cf-to:before{background-image:url(flat16/to.png)}
.cf-16.cf-tt:before{background-image:url(flat16/tt.png)}
.cf-16.cf-tn:before{background-image:url(flat16/tn.png)}
.cf-16.cf-tr:before{background-image:url(flat16/tr.png)}
.cf-16.cf-tm:before{background-image:url(flat16/tm.png)}
.cf-16.cf-tc:before{background-image:url(flat16/tc.png)}
.cf-16.cf-tv:before{background-image:url(flat16/tv.png)}
.cf-16.cf-ug:before{background-image:url(flat16/ug.png)}
.cf-16.cf-ua:before{background-image:url(flat16/ua.png)}
.cf-16.cf-ae:before{background-image:url(flat16/ae.png)}
.cf-16.cf-gb:before{background-image:url(flat16/gb.png)}
.cf-16.cf-us:before{background-image:url(flat16/us.png)}
.cf-16.cf-uy:before{background-image:url(flat16/uy.png)}
.cf-16.cf-uz:before{background-image:url(flat16/uz.png)}
.cf-16.cf-vu:before{background-image:url(flat16/vu.png)}
.cf-16.cf-va:before{background-image:url(flat16/va.png)}
.cf-16.cf-ve:before{background-image:url(flat16/ve.png)}
.cf-16.cf-vn:before{background-image:url(flat16/vn.png)}
.cf-16.cf-wf:before{background-image:url(flat16/wf.png)}
.cf-16.cf-eh:before{background-image:url(flat16/eh.png)}
.cf-16.cf-ye:before{background-image:url(flat16/ye.png)}
.cf-16.cf-zm:before{background-image:url(flat16/zm.png)}
.cf-16.cf-zw:before{background-image:url(flat16/zw.png)}
.cf-24{display:inline-block;vertical-align:middle}
.cf-24:before{content:"";display:block;background:no-repeat 50% 50%;background-size:contain;width:24px;height:24px}
.cf-24.cf-af:before{background-image:url(flat24/af.png)}
.cf-24.cf-al:before{background-image:url(flat24/al.png)}
.cf-24.cf-dz:before{background-image:url(flat24/dz.png)}
.cf-24.cf-as:before{background-image:url(flat24/as.png)}
.cf-24.cf-ad:before{background-image:url(flat24/ad.png)}
.cf-24.cf-ao:before{background-image:url(flat24/ao.png)}
.cf-24.cf-ai:before{background-image:url(flat24/ai.png)}
.cf-24.cf-aq:before{background-image:url(flat24/aq.png)}
.cf-24.cf-ag:before{background-image:url(flat24/ag.png)}
.cf-24.cf-ar:before{background-image:url(flat24/ar.png)}
.cf-24.cf-am:before{background-image:url(flat24/am.png)}
.cf-24.cf-aw:before{background-image:url(flat24/aw.png)}
.cf-24.cf-au:before{background-image:url(flat24/au.png)}
.cf-24.cf-at:before{background-image:url(flat24/at.png)}
.cf-24.cf-az:before{background-image:url(flat24/az.png)}
.cf-24.cf-bs:before{background-image:url(flat24/bs.png)}
.cf-24.cf-bh:before{background-image:url(flat24/bh.png)}
.cf-24.cf-bd:before{background-image:url(flat24/bd.png)}
.cf-24.cf-bb:before{background-image:url(flat24/bb.png)}
.cf-24.cf-by:before{background-image:url(flat24/by.png)}
.cf-24.cf-be:before{background-image:url(flat24/be.png)}
.cf-24.cf-bz:before{background-image:url(flat24/bz.png)}
.cf-24.cf-bj:before{background-image:url(flat24/bj.png)}
.cf-24.cf-bm:before{background-image:url(flat24/bm.png)}
.cf-24.cf-bt:before{background-image:url(flat24/bt.png)}
.cf-24.cf-bo:before{background-image:url(flat24/bo.png)}
.cf-24.cf-ba:before{background-image:url(flat24/ba.png)}
.cf-24.cf-bw:before{background-image:url(flat24/bw.png)}
.cf-24.cf-br:before{background-image:url(flat24/br.png)}
.cf-24.cf-vg:before{background-image:url(flat24/vg.png)}
.cf-24.cf-bn:before{background-image:url(flat24/bn.png)}
.cf-24.cf-bg:before{background-image:url(flat24/bg.png)}
.cf-24.cf-bf:before{background-image:url(flat24/bf.png)}
.cf-24.cf-bi:before{background-image:url(flat24/bi.png)}
.cf-24.cf-kh:before{background-image:url(flat24/kh.png)}
.cf-24.cf-cm:before{background-image:url(flat24/cm.png)}
.cf-24.cf-ca:before{background-image:url(flat24/ca.png)}
.cf-24.cf-cv:before{background-image:url(flat24/cv.png)}
.cf-24.cf-ky:before{background-image:url(flat24/ky.png)}
.cf-24.cf-cf:before{background-image:url(flat24/cf.png)}
.cf-24.cf-td:before{background-image:url(flat24/td.png)}
.cf-24.cf-cl:before{background-image:url(flat24/cl.png)}
.cf-24.cf-cn:before{background-image:url(flat24/cn.png)}
.cf-24.cf-cx:before{background-image:url(flat24/cx.png)}
.cf-24.cf-co:before{background-image:url(flat24/co.png)}
.cf-24.cf-km:before{background-image:url(flat24/km.png)}
.cf-24.cf-ck:before{background-image:url(flat24/ck.png)}
.cf-24.cf-cr:before{background-image:url(flat24/cr.png)}
.cf-24.cf-hr:before{background-image:url(flat24/hr.png)}
.cf-24.cf-cu:before{background-image:url(flat24/cu.png)}
.cf-24.cf-cy:before{background-image:url(flat24/cy.png)}
.cf-24.cf-cz:before{background-image:url(flat24/cz.png)}
.cf-24.cf-cd:before{background-image:url(flat24/cd.png)}
.cf-24.cf-dk:before{background-image:url(flat24/dk.png)}
.cf-24.cf-dj:before{background-image:url(flat24/dj.png)}
.cf-24.cf-dm:before{background-image:url(flat24/dm.png)}
.cf-24.cf-do:before{background-image:url(flat24/do.png)}
.cf-24.cf-ec:before{background-image:url(flat24/ec.png)}
.cf-24.cf-eg:before{background-image:url(flat24/eg.png)}
.cf-24.cf-sv:before{background-image:url(flat24/sv.png)}
.cf-24.cf-gq:before{background-image:url(flat24/gq.png)}
.cf-24.cf-er:before{background-image:url(flat24/er.png)}
.cf-24.cf-ee:before{background-image:url(flat24/ee.png)}
.cf-24.cf-et:before{background-image:url(flat24/et.png)}
.cf-24.cf-fk:before{background-image:url(flat24/fk.png)}
.cf-24.cf-fj:before{background-image:url(flat24/fj.png)}
.cf-24.cf-fi:before{background-image:url(flat24/fi.png)}
.cf-24.cf-fr:before{background-image:url(flat24/fr.png)}
.cf-24.cf-pf:before{background-image:url(flat24/pf.png)}
.cf-24.cf-ga:before{background-image:url(flat24/ga.png)}
.cf-24.cf-gm:before{background-image:url(flat24/gm.png)}
.cf-24.cf-ge:before{background-image:url(flat24/ge.png)}
.cf-24.cf-de:before{background-image:url(flat24/de.png)}
.cf-24.cf-gh:before{background-image:url(flat24/gh.png)}
.cf-24.cf-gi:before{background-image:url(flat24/gi.png)}
.cf-24.cf-gr:before{background-image:url(flat24/gr.png)}
.cf-24.cf-gl:before{background-image:url(flat24/gl.png)}
.cf-24.cf-gd:before{background-image:url(flat24/gd.png)}
.cf-24.cf-gu:before{background-image:url(flat24/gu.png)}
.cf-24.cf-gt:before{background-image:url(flat24/gt.png)}
.cf-24.cf-gg:before{background-image:url(flat24/gg.png)}
.cf-24.cf-gn:before{background-image:url(flat24/gn.png)}
.cf-24.cf-gy:before{background-image:url(flat24/gy.png)}
.cf-24.cf-ht:before{background-image:url(flat24/ht.png)}
.cf-24.cf-hn:before{background-image:url(flat24/hn.png)}
.cf-24.cf-hk:before{background-image:url(flat24/hk.png)}
.cf-24.cf-hu:before{background-image:url(flat24/hu.png)}
.cf-24.cf-is:before{background-image:url(flat24/is.png)}
.cf-24.cf-in:before{background-image:url(flat24/in.png)}
.cf-24.cf-id:before{background-image:url(flat24/id.png)}
.cf-24.cf-ir:before{background-image:url(flat24/ir.png)}
.cf-24.cf-iq:before{background-image:url(flat24/iq.png)}
.cf-24.cf-ie:before{background-image:url(flat24/ie.png)}
.cf-24.cf-im:before{background-image:url(flat24/im.png)}
.cf-24.cf-il:before{background-image:url(flat24/il.png)}
.cf-24.cf-it:before{background-image:url(flat24/it.png)}
.cf-24.cf-jm:before{background-image:url(flat24/jm.png)}
.cf-24.cf-jp:before{background-image:url(flat24/jp.png)}
.cf-24.cf-je:before{background-image:url(flat24/je.png)}
.cf-24.cf-jo:before{background-image:url(flat24/jo.png)}
.cf-24.cf-kz:before{background-image:url(flat24/kz.png)}
.cf-24.cf-ke:before{background-image:url(flat24/ke.png)}
.cf-24.cf-ki:before{background-image:url(flat24/ki.png)}
.cf-24.cf-xk:before{background-image:url(flat24/xk.png)}
.cf-24.cf-kw:before{background-image:url(flat24/kw.png)}
.cf-24.cf-kg:before{background-image:url(flat24/kg.png)}
.cf-24.cf-la:before{background-image:url(flat24/la.png)}
.cf-24.cf-lv:before{background-image:url(flat24/lv.png)}
.cf-24.cf-lb:before{background-image:url(flat24/lb.png)}
.cf-24.cf-ls:before{background-image:url(flat24/ls.png)}
.cf-24.cf-lr:before{background-image:url(flat24/lr.png)}
.cf-24.cf-ly:before{background-image:url(flat24/ly.png)}
.cf-24.cf-li:before{background-image:url(flat24/li.png)}
.cf-24.cf-lt:before{background-image:url(flat24/lt.png)}
.cf-24.cf-lu:before{background-image:url(flat24/lu.png)}
.cf-24.cf-mo:before{background-image:url(flat24/mo.png)}
.cf-24.cf-mk:before{background-image:url(flat24/mk.png)}
.cf-24.cf-mg:before{background-image:url(flat24/mg.png)}
.cf-24.cf-mw:before{background-image:url(flat24/mw.png)}
.cf-24.cf-my:before{background-image:url(flat24/my.png)}
.cf-24.cf-mv:before{background-image:url(flat24/mv.png)}
.cf-24.cf-ml:before{background-image:url(flat24/ml.png)}
.cf-24.cf-mt:before{background-image:url(flat24/mt.png)}
.cf-24.cf-mh:before{background-image:url(flat24/mh.png)}
.cf-24.cf-mq:before{background-image:url(flat24/mq.png)}
.cf-24.cf-mr:before{background-image:url(flat24/mr.png)}
.cf-24.cf-mu:before{background-image:url(flat24/mu.png)}
.cf-24.cf-yt:before{background-image:url(flat24/yt.png)}
.cf-24.cf-mx:before{background-image:url(flat24/mx.png)}
.cf-24.cf-fm:before{background-image:url(flat24/fm.png)}
.cf-24.cf-md:before{background-image:url(flat24/md.png)}
.cf-24.cf-mc:before{background-image:url(flat24/mc.png)}
.cf-24.cf-mn:before{background-image:url(flat24/mn.png)}
.cf-24.cf-me:before{background-image:url(flat24/me.png)}
.cf-24.cf-ms:before{background-image:url(flat24/ms.png)}
.cf-24.cf-ma:before{background-image:url(flat24/ma.png)}
.cf-24.cf-mz:before{background-image:url(flat24/mz.png)}
.cf-24.cf-mm:before{background-image:url(flat24/mm.png)}
.cf-24.cf-na:before{background-image:url(flat24/na.png)}
.cf-24.cf-nr:before{background-image:url(flat24/nr.png)}
.cf-24.cf-np:before{background-image:url(flat24/np.png)}
.cf-24.cf-nl:before{background-image:url(flat24/nl.png)}
.cf-24.cf-nc:before{background-image:url(flat24/nc.png)}
.cf-24.cf-nz:before{background-image:url(flat24/nz.png)}
.cf-24.cf-ni:before{background-image:url(flat24/ni.png)}
.cf-24.cf-ne:before{background-image:url(flat24/ne.png)}
.cf-24.cf-ng:before{background-image:url(flat24/ng.png)}
.cf-24.cf-nu:before{background-image:url(flat24/nu.png)}
.cf-24.cf-nf:before{background-image:url(flat24/nf.png)}
.cf-24.cf-kp:before{background-image:url(flat24/kp.png)}
.cf-24.cf-mp:before{background-image:url(flat24/mp.png)}
.cf-24.cf-no:before{background-image:url(flat24/no.png)}
.cf-24.cf-om:before{background-image:url(flat24/om.png)}
.cf-24.cf-pk:before{background-image:url(flat24/pk.png)}
.cf-24.cf-pw:before{background-image:url(flat24/pw.png)}
.cf-24.cf-ps:before{background-image:url(flat24/ps.png)}
.cf-24.cf-pa:before{background-image:url(flat24/pa.png)}
.cf-24.cf-pg:before{background-image:url(flat24/pg.png)}
.cf-24.cf-py:before{background-image:url(flat24/py.png)}
.cf-24.cf-pe:before{background-image:url(flat24/pe.png)}
.cf-24.cf-ph:before{background-image:url(flat24/ph.png)}
.cf-24.cf-pn:before{background-image:url(flat24/pn.png)}
.cf-24.cf-pl:before{background-image:url(flat24/pl.png)}
.cf-24.cf-pt:before{background-image:url(flat24/pt.png)}
.cf-24.cf-pr:before{background-image:url(flat24/pr.png)}
.cf-24.cf-qa:before{background-image:url(flat24/qa.png)}
.cf-24.cf-cg:before{background-image:url(flat24/cg.png)}
.cf-24.cf-ro:before{background-image:url(flat24/ro.png)}
.cf-24.cf-ru:before{background-image:url(flat24/ru.png)}
.cf-24.cf-rw:before{background-image:url(flat24/rw.png)}
.cf-24.cf-kn:before{background-image:url(flat24/kn.png)}
.cf-24.cf-lc:before{background-image:url(flat24/lc.png)}
.cf-24.cf-mf:before{background-image:url(flat24/mf.png)}
.cf-24.cf-vc:before{background-image:url(flat24/vc.png)}
.cf-24.cf-ws:before{background-image:url(flat24/ws.png)}
.cf-24.cf-sm:before{background-image:url(flat24/sm.png)}
.cf-24.cf-sa:before{background-image:url(flat24/sa.png)}
.cf-24.cf-sn:before{background-image:url(flat24/sn.png)}
.cf-24.cf-rs:before{background-image:url(flat24/rs.png)}
.cf-24.cf-sc:before{background-image:url(flat24/sc.png)}
.cf-24.cf-sl:before{background-image:url(flat24/sl.png)}
.cf-24.cf-sg:before{background-image:url(flat24/sg.png)}
.cf-24.cf-sk:before{background-image:url(flat24/sk.png)}
.cf-24.cf-si:before{background-image:url(flat24/si.png)}
.cf-24.cf-sb:before{background-image:url(flat24/sb.png)}
.cf-24.cf-so:before{background-image:url(flat24/so.png)}
.cf-24.cf-za:before{background-image:url(flat24/za.png)}
.cf-24.cf-gs:before{background-image:url(flat24/gs.png)}
.cf-24.cf-kr:before{background-image:url(flat24/kr.png)}
.cf-24.cf-ss:before{background-image:url(flat24/ss.png)}
.cf-24.cf-es:before{background-image:url(flat24/es.png)}
.cf-24.cf-lk:before{background-image:url(flat24/lk.png)}
.cf-24.cf-sd:before{background-image:url(flat24/sd.png)}
.cf-24.cf-sr:before{background-image:url(flat24/sr.png)}
.cf-24.cf-sz:before{background-image:url(flat24/sz.png)}
.cf-24.cf-se:before{background-image:url(flat24/se.png)}
.cf-24.cf-ch:before{background-image:url(flat24/ch.png)}
.cf-24.cf-sy:before{background-image:url(flat24/sy.png)}
.cf-24.cf-tw:before{background-image:url(flat24/tw.png)}
.cf-24.cf-tj:before{background-image:url(flat24/tj.png)}
.cf-24.cf-tz:before{background-image:url(flat24/tz.png)}
.cf-24.cf-th:before{background-image:url(flat24/th.png)}
.cf-24.cf-tg:before{background-image:url(flat24/tg.png)}
.cf-24.cf-tk:before{background-image:url(flat24/tk.png)}
.cf-24.cf-to:before{background-image:url(flat24/to.png)}
.cf-24.cf-tt:before{background-image:url(flat24/tt.png)}
.cf-24.cf-tn:before{background-image:url(flat24/tn.png)}
.cf-24.cf-tr:before{background-image:url(flat24/tr.png)}
.cf-24.cf-tm:before{background-image:url(flat24/tm.png)}
.cf-24.cf-tc:before{background-image:url(flat24/tc.png)}
.cf-24.cf-tv:before{background-image:url(flat24/tv.png)}
.cf-24.cf-ug:before{background-image:url(flat24/ug.png)}
.cf-24.cf-ua:before{background-image:url(flat24/ua.png)}
.cf-24.cf-ae:before{background-image:url(flat24/ae.png)}
.cf-24.cf-gb:before{background-image:url(flat24/gb.png)}
.cf-24.cf-us:before{background-image:url(flat24/us.png)}
.cf-24.cf-uy:before{background-image:url(flat24/uy.png)}
.cf-24.cf-uz:before{background-image:url(flat24/uz.png)}
.cf-24.cf-vu:before{background-image:url(flat24/vu.png)}
.cf-24.cf-va:before{background-image:url(flat24/va.png)}
.cf-24.cf-ve:before{background-image:url(flat24/ve.png)}
.cf-24.cf-vn:before{background-image:url(flat24/vn.png)}
.cf-24.cf-wf:before{background-image:url(flat24/wf.png)}
.cf-24.cf-eh:before{background-image:url(flat24/eh.png)}
.cf-24.cf-ye:before{background-image:url(flat24/ye.png)}
.cf-24.cf-zm:before{background-image:url(flat24/zm.png)}
.cf-24.cf-zw:before{background-image:url(flat24/zw.png)}
.cf-32{display:inline-block;vertical-align:middle}
.cf-32:before{content:"";display:block;background:no-repeat 50% 50%;background-size:contain;width:32px;height:32px}
.cf-32.cf-af:before{background-image:url(flat32/af.png)}
.cf-32.cf-al:before{background-image:url(flat32/al.png)}
.cf-32.cf-dz:before{background-image:url(flat32/dz.png)}
.cf-32.cf-as:before{background-image:url(flat32/as.png)}
.cf-32.cf-ad:before{background-image:url(flat32/ad.png)}
.cf-32.cf-ao:before{background-image:url(flat32/ao.png)}
.cf-32.cf-ai:before{background-image:url(flat32/ai.png)}
.cf-32.cf-aq:before{background-image:url(flat32/aq.png)}
.cf-32.cf-ag:before{background-image:url(flat32/ag.png)}
.cf-32.cf-ar:before{background-image:url(flat32/ar.png)}
.cf-32.cf-am:before{background-image:url(flat32/am.png)}
.cf-32.cf-aw:before{background-image:url(flat32/aw.png)}
.cf-32.cf-au:before{background-image:url(flat32/au.png)}
.cf-32.cf-at:before{background-image:url(flat32/at.png)}
.cf-32.cf-az:before{background-image:url(flat32/az.png)}
.cf-32.cf-bs:before{background-image:url(flat32/bs.png)}
.cf-32.cf-bh:before{background-image:url(flat32/bh.png)}
.cf-32.cf-bd:before{background-image:url(flat32/bd.png)}
.cf-32.cf-bb:before{background-image:url(flat32/bb.png)}
.cf-32.cf-by:before{background-image:url(flat32/by.png)}
.cf-32.cf-be:before{background-image:url(flat32/be.png)}
.cf-32.cf-bz:before{background-image:url(flat32/bz.png)}
.cf-32.cf-bj:before{background-image:url(flat32/bj.png)}
.cf-32.cf-bm:before{background-image:url(flat32/bm.png)}
.cf-32.cf-bt:before{background-image:url(flat32/bt.png)}
.cf-32.cf-bo:before{background-image:url(flat32/bo.png)}
.cf-32.cf-ba:before{background-image:url(flat32/ba.png)}
.cf-32.cf-bw:before{background-image:url(flat32/bw.png)}
.cf-32.cf-br:before{background-image:url(flat32/br.png)}
.cf-32.cf-vg:before{background-image:url(flat32/vg.png)}
.cf-32.cf-bn:before{background-image:url(flat32/bn.png)}
.cf-32.cf-bg:before{background-image:url(flat32/bg.png)}
.cf-32.cf-bf:before{background-image:url(flat32/bf.png)}
.cf-32.cf-bi:before{background-image:url(flat32/bi.png)}
.cf-32.cf-kh:before{background-image:url(flat32/kh.png)}
.cf-32.cf-cm:before{background-image:url(flat32/cm.png)}
.cf-32.cf-ca:before{background-image:url(flat32/ca.png)}
.cf-32.cf-cv:before{background-image:url(flat32/cv.png)}
.cf-32.cf-ky:before{background-image:url(flat32/ky.png)}
.cf-32.cf-cf:before{background-image:url(flat32/cf.png)}
.cf-32.cf-td:before{background-image:url(flat32/td.png)}
.cf-32.cf-cl:before{background-image:url(flat32/cl.png)}
.cf-32.cf-cn:before{background-image:url(flat32/cn.png)}
.cf-32.cf-cx:before{background-image:url(flat32/cx.png)}
.cf-32.cf-co:before{background-image:url(flat32/co.png)}
.cf-32.cf-km:before{background-image:url(flat32/km.png)}
.cf-32.cf-ck:before{background-image:url(flat32/ck.png)}
.cf-32.cf-cr:before{background-image:url(flat32/cr.png)}
.cf-32.cf-hr:before{background-image:url(flat32/hr.png)}
.cf-32.cf-cu:before{background-image:url(flat32/cu.png)}
.cf-32.cf-cy:before{background-image:url(flat32/cy.png)}
.cf-32.cf-cz:before{background-image:url(flat32/cz.png)}
.cf-32.cf-cd:before{background-image:url(flat32/cd.png)}
.cf-32.cf-dk:before{background-image:url(flat32/dk.png)}
.cf-32.cf-dj:before{background-image:url(flat32/dj.png)}
.cf-32.cf-dm:before{background-image:url(flat32/dm.png)}
.cf-32.cf-do:before{background-image:url(flat32/do.png)}
.cf-32.cf-ec:before{background-image:url(flat32/ec.png)}
.cf-32.cf-eg:before{background-image:url(flat32/eg.png)}
.cf-32.cf-sv:before{background-image:url(flat32/sv.png)}
.cf-32.cf-gq:before{background-image:url(flat32/gq.png)}
.cf-32.cf-er:before{background-image:url(flat32/er.png)}
.cf-32.cf-ee:before{background-image:url(flat32/ee.png)}
.cf-32.cf-et:before{background-image:url(flat32/et.png)}
.cf-32.cf-fk:before{background-image:url(flat32/fk.png)}
.cf-32.cf-fj:before{background-image:url(flat32/fj.png)}
.cf-32.cf-fi:before{background-image:url(flat32/fi.png)}
.cf-32.cf-fr:before{background-image:url(flat32/fr.png)}
.cf-32.cf-pf:before{background-image:url(flat32/pf.png)}
.cf-32.cf-ga:before{background-image:url(flat32/ga.png)}
.cf-32.cf-gm:before{background-image:url(flat32/gm.png)}
.cf-32.cf-ge:before{background-image:url(flat32/ge.png)}
.cf-32.cf-de:before{background-image:url(flat32/de.png)}
.cf-32.cf-gh:before{background-image:url(flat32/gh.png)}
.cf-32.cf-gi:before{background-image:url(flat32/gi.png)}
.cf-32.cf-gr:before{background-image:url(flat32/gr.png)}
.cf-32.cf-gl:before{background-image:url(flat32/gl.png)}
.cf-32.cf-gd:before{background-image:url(flat32/gd.png)}
.cf-32.cf-gu:before{background-image:url(flat32/gu.png)}
.cf-32.cf-gt:before{background-image:url(flat32/gt.png)}
.cf-32.cf-gg:before{background-image:url(flat32/gg.png)}
.cf-32.cf-gn:before{background-image:url(flat32/gn.png)}
.cf-32.cf-gy:before{background-image:url(flat32/gy.png)}
.cf-32.cf-ht:before{background-image:url(flat32/ht.png)}
.cf-32.cf-hn:before{background-image:url(flat32/hn.png)}
.cf-32.cf-hk:before{background-image:url(flat32/hk.png)}
.cf-32.cf-hu:before{background-image:url(flat32/hu.png)}
.cf-32.cf-is:before{background-image:url(flat32/is.png)}
.cf-32.cf-in:before{background-image:url(flat32/in.png)}
.cf-32.cf-id:before{background-image:url(flat32/id.png)}
.cf-32.cf-ir:before{background-image:url(flat32/ir.png)}
.cf-32.cf-iq:before{background-image:url(flat32/iq.png)}
.cf-32.cf-ie:before{background-image:url(flat32/ie.png)}
.cf-32.cf-im:before{background-image:url(flat32/im.png)}
.cf-32.cf-il:before{background-image:url(flat32/il.png)}
.cf-32.cf-it:before{background-image:url(flat32/it.png)}
.cf-32.cf-jm:before{background-image:url(flat32/jm.png)}
.cf-32.cf-jp:before{background-image:url(flat32/jp.png)}
.cf-32.cf-je:before{background-image:url(flat32/je.png)}
.cf-32.cf-jo:before{background-image:url(flat32/jo.png)}
.cf-32.cf-kz:before{background-image:url(flat32/kz.png)}
.cf-32.cf-ke:before{background-image:url(flat32/ke.png)}
.cf-32.cf-ki:before{background-image:url(flat32/ki.png)}
.cf-32.cf-xk:before{background-image:url(flat32/xk.png)}
.cf-32.cf-kw:before{background-image:url(flat32/kw.png)}
.cf-32.cf-kg:before{background-image:url(flat32/kg.png)}
.cf-32.cf-la:before{background-image:url(flat32/la.png)}
.cf-32.cf-lv:before{background-image:url(flat32/lv.png)}
.cf-32.cf-lb:before{background-image:url(flat32/lb.png)}
.cf-32.cf-ls:before{background-image:url(flat32/ls.png)}
.cf-32.cf-lr:before{background-image:url(flat32/lr.png)}
.cf-32.cf-ly:before{background-image:url(flat32/ly.png)}
.cf-32.cf-li:before{background-image:url(flat32/li.png)}
.cf-32.cf-lt:before{background-image:url(flat32/lt.png)}
.cf-32.cf-lu:before{background-image:url(flat32/lu.png)}
.cf-32.cf-mo:before{background-image:url(flat32/mo.png)}
.cf-32.cf-mk:before{background-image:url(flat32/mk.png)}
.cf-32.cf-mg:before{background-image:url(flat32/mg.png)}
.cf-32.cf-mw:before{background-image:url(flat32/mw.png)}
.cf-32.cf-my:before{background-image:url(flat32/my.png)}
.cf-32.cf-mv:before{background-image:url(flat32/mv.png)}
.cf-32.cf-ml:before{background-image:url(flat32/ml.png)}
.cf-32.cf-mt:before{background-image:url(flat32/mt.png)}
.cf-32.cf-mh:before{background-image:url(flat32/mh.png)}
.cf-32.cf-mq:before{background-image:url(flat32/mq.png)}
.cf-32.cf-mr:before{background-image:url(flat32/mr.png)}
.cf-32.cf-mu:before{background-image:url(flat32/mu.png)}
.cf-32.cf-yt:before{background-image:url(flat32/yt.png)}
.cf-32.cf-mx:before{background-image:url(flat32/mx.png)}
.cf-32.cf-fm:before{background-image:url(flat32/fm.png)}
.cf-32.cf-md:before{background-image:url(flat32/md.png)}
.cf-32.cf-mc:before{background-image:url(flat32/mc.png)}
.cf-32.cf-mn:before{background-image:url(flat32/mn.png)}
.cf-32.cf-me:before{background-image:url(flat32/me.png)}
.cf-32.cf-ms:before{background-image:url(flat32/ms.png)}
.cf-32.cf-ma:before{background-image:url(flat32/ma.png)}
.cf-32.cf-mz:before{background-image:url(flat32/mz.png)}
.cf-32.cf-mm:before{background-image:url(flat32/mm.png)}
.cf-32.cf-na:before{background-image:url(flat32/na.png)}
.cf-32.cf-nr:before{background-image:url(flat32/nr.png)}
.cf-32.cf-np:before{background-image:url(flat32/np.png)}
.cf-32.cf-nl:before{background-image:url(flat32/nl.png)}
.cf-32.cf-nc:before{background-image:url(flat32/nc.png)}
.cf-32.cf-nz:before{background-image:url(flat32/nz.png)}
.cf-32.cf-ni:before{background-image:url(flat32/ni.png)}
.cf-32.cf-ne:before{background-image:url(flat32/ne.png)}
.cf-32.cf-ng:before{background-image:url(flat32/ng.png)}
.cf-32.cf-nu:before{background-image:url(flat32/nu.png)}
.cf-32.cf-nf:before{background-image:url(flat32/nf.png)}
.cf-32.cf-kp:before{background-image:url(flat32/kp.png)}
.cf-32.cf-mp:before{background-image:url(flat32/mp.png)}
.cf-32.cf-no:before{background-image:url(flat32/no.png)}
.cf-32.cf-om:before{background-image:url(flat32/om.png)}
.cf-32.cf-pk:before{background-image:url(flat32/pk.png)}
.cf-32.cf-pw:before{background-image:url(flat32/pw.png)}
.cf-32.cf-ps:before{background-image:url(flat32/ps.png)}
.cf-32.cf-pa:before{background-image:url(flat32/pa.png)}
.cf-32.cf-pg:before{background-image:url(flat32/pg.png)}
.cf-32.cf-py:before{background-image:url(flat32/py.png)}
.cf-32.cf-pe:before{background-image:url(flat32/pe.png)}
.cf-32.cf-ph:before{background-image:url(flat32/ph.png)}
.cf-32.cf-pn:before{background-image:url(flat32/pn.png)}
.cf-32.cf-pl:before{background-image:url(flat32/pl.png)}
.cf-32.cf-pt:before{background-image:url(flat32/pt.png)}
.cf-32.cf-pr:before{background-image:url(flat32/pr.png)}
.cf-32.cf-qa:before{background-image:url(flat32/qa.png)}
.cf-32.cf-cg:before{background-image:url(flat32/cg.png)}
.cf-32.cf-ro:before{background-image:url(flat32/ro.png)}
.cf-32.cf-ru:before{background-image:url(flat32/ru.png)}
.cf-32.cf-rw:before{background-image:url(flat32/rw.png)}
.cf-32.cf-kn:before{background-image:url(flat32/kn.png)}
.cf-32.cf-lc:before{background-image:url(flat32/lc.png)}
.cf-32.cf-mf:before{background-image:url(flat32/mf.png)}
.cf-32.cf-vc:before{background-image:url(flat32/vc.png)}
.cf-32.cf-ws:before{background-image:url(flat32/ws.png)}
.cf-32.cf-sm:before{background-image:url(flat32/sm.png)}
.cf-32.cf-sa:before{background-image:url(flat32/sa.png)}
.cf-32.cf-sn:before{background-image:url(flat32/sn.png)}
.cf-32.cf-rs:before{background-image:url(flat32/rs.png)}
.cf-32.cf-sc:before{background-image:url(flat32/sc.png)}
.cf-32.cf-sl:before{background-image:url(flat32/sl.png)}
.cf-32.cf-sg:before{background-image:url(flat32/sg.png)}
.cf-32.cf-sk:before{background-image:url(flat32/sk.png)}
.cf-32.cf-si:before{background-image:url(flat32/si.png)}
.cf-32.cf-sb:before{background-image:url(flat32/sb.png)}
.cf-32.cf-so:before{background-image:url(flat32/so.png)}
.cf-32.cf-za:before{background-image:url(flat32/za.png)}
.cf-32.cf-gs:before{background-image:url(flat32/gs.png)}
.cf-32.cf-kr:before{background-image:url(flat32/kr.png)}
.cf-32.cf-ss:before{background-image:url(flat32/ss.png)}
.cf-32.cf-es:before{background-image:url(flat32/es.png)}
.cf-32.cf-lk:before{background-image:url(flat32/lk.png)}
.cf-32.cf-sd:before{background-image:url(flat32/sd.png)}
.cf-32.cf-sr:before{background-image:url(flat32/sr.png)}
.cf-32.cf-sz:before{background-image:url(flat32/sz.png)}
.cf-32.cf-se:before{background-image:url(flat32/se.png)}
.cf-32.cf-ch:before{background-image:url(flat32/ch.png)}
.cf-32.cf-sy:before{background-image:url(flat32/sy.png)}
.cf-32.cf-tw:before{background-image:url(flat32/tw.png)}
.cf-32.cf-tj:before{background-image:url(flat32/tj.png)}
.cf-32.cf-tz:before{background-image:url(flat32/tz.png)}
.cf-32.cf-th:before{background-image:url(flat32/th.png)}
.cf-32.cf-tg:before{background-image:url(flat32/tg.png)}
.cf-32.cf-tk:before{background-image:url(flat32/tk.png)}
.cf-32.cf-to:before{background-image:url(flat32/to.png)}
.cf-32.cf-tt:before{background-image:url(flat32/tt.png)}
.cf-32.cf-tn:before{background-image:url(flat32/tn.png)}
.cf-32.cf-tr:before{background-image:url(flat32/tr.png)}
.cf-32.cf-tm:before{background-image:url(flat32/tm.png)}
.cf-32.cf-tc:before{background-image:url(flat32/tc.png)}
.cf-32.cf-tv:before{background-image:url(flat32/tv.png)}
.cf-32.cf-ug:before{background-image:url(flat32/ug.png)}
.cf-32.cf-ua:before{background-image:url(flat32/ua.png)}
.cf-32.cf-ae:before{background-image:url(flat32/ae.png)}
.cf-32.cf-gb:before{background-image:url(flat32/gb.png)}
.cf-32.cf-us:before{background-image:url(flat32/us.png)}
.cf-32.cf-uy:before{background-image:url(flat32/uy.png)}
.cf-32.cf-uz:before{background-image:url(flat32/uz.png)}
.cf-32.cf-vu:before{background-image:url(flat32/vu.png)}
.cf-32.cf-va:before{background-image:url(flat32/va.png)}
.cf-32.cf-ve:before{background-image:url(flat32/ve.png)}
.cf-32.cf-vn:before{background-image:url(flat32/vn.png)}
.cf-32.cf-wf:before{background-image:url(flat32/wf.png)}
.cf-32.cf-eh:before{background-image:url(flat32/eh.png)}
.cf-32.cf-ye:before{background-image:url(flat32/ye.png)}
.cf-32.cf-zm:before{background-image:url(flat32/zm.png)}
.cf-32.cf-zw:before{background-image:url(flat32/zw.png)}
.cf-48{display:inline-block;vertical-align:middle}
.cf-48:before{content:"";display:block;background:no-repeat 50% 50%;background-size:contain;width:48px;height:48px}
.cf-48.cf-af:before{background-image:url(flat48/af.png)}
.cf-48.cf-al:before{background-image:url(flat48/al.png)}
.cf-48.cf-dz:before{background-image:url(flat48/dz.png)}
.cf-48.cf-as:before{background-image:url(flat48/as.png)}
.cf-48.cf-ad:before{background-image:url(flat48/ad.png)}
.cf-48.cf-ao:before{background-image:url(flat48/ao.png)}
.cf-48.cf-ai:before{background-image:url(flat48/ai.png)}
.cf-48.cf-aq:before{background-image:url(flat48/aq.png)}
.cf-48.cf-ag:before{background-image:url(flat48/ag.png)}
.cf-48.cf-ar:before{background-image:url(flat48/ar.png)}
.cf-48.cf-am:before{background-image:url(flat48/am.png)}
.cf-48.cf-aw:before{background-image:url(flat48/aw.png)}
.cf-48.cf-au:before{background-image:url(flat48/au.png)}
.cf-48.cf-at:before{background-image:url(flat48/at.png)}
.cf-48.cf-az:before{background-image:url(flat48/az.png)}
.cf-48.cf-bs:before{background-image:url(flat48/bs.png)}
.cf-48.cf-bh:before{background-image:url(flat48/bh.png)}
.cf-48.cf-bd:before{background-image:url(flat48/bd.png)}
.cf-48.cf-bb:before{background-image:url(flat48/bb.png)}
.cf-48.cf-by:before{background-image:url(flat48/by.png)}
.cf-48.cf-be:before{background-image:url(flat48/be.png)}
.cf-48.cf-bz:before{background-image:url(flat48/bz.png)}
.cf-48.cf-bj:before{background-image:url(flat48/bj.png)}
.cf-48.cf-bm:before{background-image:url(flat48/bm.png)}
.cf-48.cf-bt:before{background-image:url(flat48/bt.png)}
.cf-48.cf-bo:before{background-image:url(flat48/bo.png)}
.cf-48.cf-ba:before{background-image:url(flat48/ba.png)}
.cf-48.cf-bw:before{background-image:url(flat48/bw.png)}
.cf-48.cf-br:before{background-image:url(flat48/br.png)}
.cf-48.cf-vg:before{background-image:url(flat48/vg.png)}
.cf-48.cf-bn:before{background-image:url(flat48/bn.png)}
.cf-48.cf-bg:before{background-image:url(flat48/bg.png)}
.cf-48.cf-bf:before{background-image:url(flat48/bf.png)}
.cf-48.cf-bi:before{background-image:url(flat48/bi.png)}
.cf-48.cf-kh:before{background-image:url(flat48/kh.png)}
.cf-48.cf-cm:before{background-image:url(flat48/cm.png)}
.cf-48.cf-ca:before{background-image:url(flat48/ca.png)}
.cf-48.cf-cv:before{background-image:url(flat48/cv.png)}
.cf-48.cf-ky:before{background-image:url(flat48/ky.png)}
.cf-48.cf-cf:before{background-image:url(flat48/cf.png)}
.cf-48.cf-td:before{background-image:url(flat48/td.png)}
.cf-48.cf-cl:before{background-image:url(flat48/cl.png)}
.cf-48.cf-cn:before{background-image:url(flat48/cn.png)}
.cf-48.cf-cx:before{background-image:url(flat48/cx.png)}
.cf-48.cf-co:before{background-image:url(flat48/co.png)}
.cf-48.cf-km:before{background-image:url(flat48/km.png)}
.cf-48.cf-ck:before{background-image:url(flat48/ck.png)}
.cf-48.cf-cr:before{background-image:url(flat48/cr.png)}
.cf-48.cf-hr:before{background-image:url(flat48/hr.png)}
.cf-48.cf-cu:before{background-image:url(flat48/cu.png)}
.cf-48.cf-cy:before{background-image:url(flat48/cy.png)}
.cf-48.cf-cz:before{background-image:url(flat48/cz.png)}
.cf-48.cf-cd:before{background-image:url(flat48/cd.png)}
.cf-48.cf-dk:before{background-image:url(flat48/dk.png)}
.cf-48.cf-dj:before{background-image:url(flat48/dj.png)}
.cf-48.cf-dm:before{background-image:url(flat48/dm.png)}
.cf-48.cf-do:before{background-image:url(flat48/do.png)}
.cf-48.cf-ec:before{background-image:url(flat48/ec.png)}
.cf-48.cf-eg:before{background-image:url(flat48/eg.png)}
.cf-48.cf-sv:before{background-image:url(flat48/sv.png)}
.cf-48.cf-gq:before{background-image:url(flat48/gq.png)}
.cf-48.cf-er:before{background-image:url(flat48/er.png)}
.cf-48.cf-ee:before{background-image:url(flat48/ee.png)}
.cf-48.cf-et:before{background-image:url(flat48/et.png)}
.cf-48.cf-fk:before{background-image:url(flat48/fk.png)}
.cf-48.cf-fj:before{background-image:url(flat48/fj.png)}
.cf-48.cf-fi:before{background-image:url(flat48/fi.png)}
.cf-48.cf-fr:before{background-image:url(flat48/fr.png)}
.cf-48.cf-pf:before{background-image:url(flat48/pf.png)}
.cf-48.cf-ga:before{background-image:url(flat48/ga.png)}
.cf-48.cf-gm:before{background-image:url(flat48/gm.png)}
.cf-48.cf-ge:before{background-image:url(flat48/ge.png)}
.cf-48.cf-de:before{background-image:url(flat48/de.png)}
.cf-48.cf-gh:before{background-image:url(flat48/gh.png)}
.cf-48.cf-gi:before{background-image:url(flat48/gi.png)}
.cf-48.cf-gr:before{background-image:url(flat48/gr.png)}
.cf-48.cf-gl:before{background-image:url(flat48/gl.png)}
.cf-48.cf-gd:before{background-image:url(flat48/gd.png)}
.cf-48.cf-gu:before{background-image:url(flat48/gu.png)}
.cf-48.cf-gt:before{background-image:url(flat48/gt.png)}
.cf-48.cf-gg:before{background-image:url(flat48/gg.png)}
.cf-48.cf-gn:before{background-image:url(flat48/gn.png)}
.cf-48.cf-gy:before{background-image:url(flat48/gy.png)}
.cf-48.cf-ht:before{background-image:url(flat48/ht.png)}
.cf-48.cf-hn:before{background-image:url(flat48/hn.png)}
.cf-48.cf-hk:before{background-image:url(flat48/hk.png)}
.cf-48.cf-hu:before{background-image:url(flat48/hu.png)}
.cf-48.cf-is:before{background-image:url(flat48/is.png)}
.cf-48.cf-in:before{background-image:url(flat48/in.png)}
.cf-48.cf-id:before{background-image:url(flat48/id.png)}
.cf-48.cf-ir:before{background-image:url(flat48/ir.png)}
.cf-48.cf-iq:before{background-image:url(flat48/iq.png)}
.cf-48.cf-ie:before{background-image:url(flat48/ie.png)}
.cf-48.cf-im:before{background-image:url(flat48/im.png)}
.cf-48.cf-il:before{background-image:url(flat48/il.png)}
.cf-48.cf-it:before{background-image:url(flat48/it.png)}
.cf-48.cf-jm:before{background-image:url(flat48/jm.png)}
.cf-48.cf-jp:before{background-image:url(flat48/jp.png)}
.cf-48.cf-je:before{background-image:url(flat48/je.png)}
.cf-48.cf-jo:before{background-image:url(flat48/jo.png)}
.cf-48.cf-kz:before{background-image:url(flat48/kz.png)}
.cf-48.cf-ke:before{background-image:url(flat48/ke.png)}
.cf-48.cf-ki:before{background-image:url(flat48/ki.png)}
.cf-48.cf-xk:before{background-image:url(flat48/xk.png)}
.cf-48.cf-kw:before{background-image:url(flat48/kw.png)}
.cf-48.cf-kg:before{background-image:url(flat48/kg.png)}
.cf-48.cf-la:before{background-image:url(flat48/la.png)}
.cf-48.cf-lv:before{background-image:url(flat48/lv.png)}
.cf-48.cf-lb:before{background-image:url(flat48/lb.png)}
.cf-48.cf-ls:before{background-image:url(flat48/ls.png)}
.cf-48.cf-lr:before{background-image:url(flat48/lr.png)}
.cf-48.cf-ly:before{background-image:url(flat48/ly.png)}
.cf-48.cf-li:before{background-image:url(flat48/li.png)}
.cf-48.cf-lt:before{background-image:url(flat48/lt.png)}
.cf-48.cf-lu:before{background-image:url(flat48/lu.png)}
.cf-48.cf-mo:before{background-image:url(flat48/mo.png)}
.cf-48.cf-mk:before{background-image:url(flat48/mk.png)}
.cf-48.cf-mg:before{background-image:url(flat48/mg.png)}
.cf-48.cf-mw:before{background-image:url(flat48/mw.png)}
.cf-48.cf-my:before{background-image:url(flat48/my.png)}
.cf-48.cf-mv:before{background-image:url(flat48/mv.png)}
.cf-48.cf-ml:before{background-image:url(flat48/ml.png)}
.cf-48.cf-mt:before{background-image:url(flat48/mt.png)}
.cf-48.cf-mh:before{background-image:url(flat48/mh.png)}
.cf-48.cf-mq:before{background-image:url(flat48/mq.png)}
.cf-48.cf-mr:before{background-image:url(flat48/mr.png)}
.cf-48.cf-mu:before{background-image:url(flat48/mu.png)}
.cf-48.cf-yt:before{background-image:url(flat48/yt.png)}
.cf-48.cf-mx:before{background-image:url(flat48/mx.png)}
.cf-48.cf-fm:before{background-image:url(flat48/fm.png)}
.cf-48.cf-md:before{background-image:url(flat48/md.png)}
.cf-48.cf-mc:before{background-image:url(flat48/mc.png)}
.cf-48.cf-mn:before{background-image:url(flat48/mn.png)}
.cf-48.cf-me:before{background-image:url(flat48/me.png)}
.cf-48.cf-ms:before{background-image:url(flat48/ms.png)}
.cf-48.cf-ma:before{background-image:url(flat48/ma.png)}
.cf-48.cf-mz:before{background-image:url(flat48/mz.png)}
.cf-48.cf-mm:before{background-image:url(flat48/mm.png)}
.cf-48.cf-na:before{background-image:url(flat48/na.png)}
.cf-48.cf-nr:before{background-image:url(flat48/nr.png)}
.cf-48.cf-np:before{background-image:url(flat48/np.png)}
.cf-48.cf-nl:before{background-image:url(flat48/nl.png)}
.cf-48.cf-nc:before{background-image:url(flat48/nc.png)}
.cf-48.cf-nz:before{background-image:url(flat48/nz.png)}
.cf-48.cf-ni:before{background-image:url(flat48/ni.png)}
.cf-48.cf-ne:before{background-image:url(flat48/ne.png)}
.cf-48.cf-ng:before{background-image:url(flat48/ng.png)}
.cf-48.cf-nu:before{background-image:url(flat48/nu.png)}
.cf-48.cf-nf:before{background-image:url(flat48/nf.png)}
.cf-48.cf-kp:before{background-image:url(flat48/kp.png)}
.cf-48.cf-mp:before{background-image:url(flat48/mp.png)}
.cf-48.cf-no:before{background-image:url(flat48/no.png)}
.cf-48.cf-om:before{background-image:url(flat48/om.png)}
.cf-48.cf-pk:before{background-image:url(flat48/pk.png)}
.cf-48.cf-pw:before{background-image:url(flat48/pw.png)}
.cf-48.cf-ps:before{background-image:url(flat48/ps.png)}
.cf-48.cf-pa:before{background-image:url(flat48/pa.png)}
.cf-48.cf-pg:before{background-image:url(flat48/pg.png)}
.cf-48.cf-py:before{background-image:url(flat48/py.png)}
.cf-48.cf-pe:before{background-image:url(flat48/pe.png)}
.cf-48.cf-ph:before{background-image:url(flat48/ph.png)}
.cf-48.cf-pn:before{background-image:url(flat48/pn.png)}
.cf-48.cf-pl:before{background-image:url(flat48/pl.png)}
.cf-48.cf-pt:before{background-image:url(flat48/pt.png)}
.cf-48.cf-pr:before{background-image:url(flat48/pr.png)}
.cf-48.cf-qa:before{background-image:url(flat48/qa.png)}
.cf-48.cf-cg:before{background-image:url(flat48/cg.png)}
.cf-48.cf-ro:before{background-image:url(flat48/ro.png)}
.cf-48.cf-ru:before{background-image:url(flat48/ru.png)}
.cf-48.cf-rw:before{background-image:url(flat48/rw.png)}
.cf-48.cf-kn:before{background-image:url(flat48/kn.png)}
.cf-48.cf-lc:before{background-image:url(flat48/lc.png)}
.cf-48.cf-mf:before{background-image:url(flat48/mf.png)}
.cf-48.cf-vc:before{background-image:url(flat48/vc.png)}
.cf-48.cf-ws:before{background-image:url(flat48/ws.png)}
.cf-48.cf-sm:before{background-image:url(flat48/sm.png)}
.cf-48.cf-sa:before{background-image:url(flat48/sa.png)}
.cf-48.cf-sn:before{background-image:url(flat48/sn.png)}
.cf-48.cf-rs:before{background-image:url(flat48/rs.png)}
.cf-48.cf-sc:before{background-image:url(flat48/sc.png)}
.cf-48.cf-sl:before{background-image:url(flat48/sl.png)}
.cf-48.cf-sg:before{background-image:url(flat48/sg.png)}
.cf-48.cf-sk:before{background-image:url(flat48/sk.png)}
.cf-48.cf-si:before{background-image:url(flat48/si.png)}
.cf-48.cf-sb:before{background-image:url(flat48/sb.png)}
.cf-48.cf-so:before{background-image:url(flat48/so.png)}
.cf-48.cf-za:before{background-image:url(flat48/za.png)}
.cf-48.cf-gs:before{background-image:url(flat48/gs.png)}
.cf-48.cf-kr:before{background-image:url(flat48/kr.png)}
.cf-48.cf-ss:before{background-image:url(flat48/ss.png)}
.cf-48.cf-es:before{background-image:url(flat48/es.png)}
.cf-48.cf-lk:before{background-image:url(flat48/lk.png)}
.cf-48.cf-sd:before{background-image:url(flat48/sd.png)}
.cf-48.cf-sr:before{background-image:url(flat48/sr.png)}
.cf-48.cf-sz:before{background-image:url(flat48/sz.png)}
.cf-48.cf-se:before{background-image:url(flat48/se.png)}
.cf-48.cf-ch:before{background-image:url(flat48/ch.png)}
.cf-48.cf-sy:before{background-image:url(flat48/sy.png)}
.cf-48.cf-tw:before{background-image:url(flat48/tw.png)}
.cf-48.cf-tj:before{background-image:url(flat48/tj.png)}
.cf-48.cf-tz:before{background-image:url(flat48/tz.png)}
.cf-48.cf-th:before{background-image:url(flat48/th.png)}
.cf-48.cf-tg:before{background-image:url(flat48/tg.png)}
.cf-48.cf-tk:before{background-image:url(flat48/tk.png)}
.cf-48.cf-to:before{background-image:url(flat48/to.png)}
.cf-48.cf-tt:before{background-image:url(flat48/tt.png)}
.cf-48.cf-tn:before{background-image:url(flat48/tn.png)}
.cf-48.cf-tr:before{background-image:url(flat48/tr.png)}
.cf-48.cf-tm:before{background-image:url(flat48/tm.png)}
.cf-48.cf-tc:before{background-image:url(flat48/tc.png)}
.cf-48.cf-tv:before{background-image:url(flat48/tv.png)}
.cf-48.cf-ug:before{background-image:url(flat48/ug.png)}
.cf-48.cf-ua:before{background-image:url(flat48/ua.png)}
.cf-48.cf-ae:before{background-image:url(flat48/ae.png)}
.cf-48.cf-gb:before{background-image:url(flat48/gb.png)}
.cf-48.cf-us:before{background-image:url(flat48/us.png)}
.cf-48.cf-uy:before{background-image:url(flat48/uy.png)}
.cf-48.cf-uz:before{background-image:url(flat48/uz.png)}
.cf-48.cf-vu:before{background-image:url(flat48/vu.png)}
.cf-48.cf-va:before{background-image:url(flat48/va.png)}
.cf-48.cf-ve:before{background-image:url(flat48/ve.png)}
.cf-48.cf-vn:before{background-image:url(flat48/vn.png)}
.cf-48.cf-wf:before{background-image:url(flat48/wf.png)}
.cf-48.cf-eh:before{background-image:url(flat48/eh.png)}
.cf-48.cf-ye:before{background-image:url(flat48/ye.png)}
.cf-48.cf-zm:before{background-image:url(flat48/zm.png)}
.cf-48.cf-zw:before{background-image:url(flat48/zw.png)}
.cf-64{display:inline-block;vertical-align:middle}
.cf-64:before{content:"";display:block;background:no-repeat 50% 50%;background-size:contain;width:64px;height:64px}
.cf-64.cf-af:before{background-image:url(flat64/af.png)}
.cf-64.cf-al:before{background-image:url(flat64/al.png)}
.cf-64.cf-dz:before{background-image:url(flat64/dz.png)}
.cf-64.cf-as:before{background-image:url(flat64/as.png)}
.cf-64.cf-ad:before{background-image:url(flat64/ad.png)}
.cf-64.cf-ao:before{background-image:url(flat64/ao.png)}
.cf-64.cf-ai:before{background-image:url(flat64/ai.png)}
.cf-64.cf-aq:before{background-image:url(flat64/aq.png)}
.cf-64.cf-ag:before{background-image:url(flat64/ag.png)}
.cf-64.cf-ar:before{background-image:url(flat64/ar.png)}
.cf-64.cf-am:before{background-image:url(flat64/am.png)}
.cf-64.cf-aw:before{background-image:url(flat64/aw.png)}
.cf-64.cf-au:before{background-image:url(flat64/au.png)}
.cf-64.cf-at:before{background-image:url(flat64/at.png)}
.cf-64.cf-az:before{background-image:url(flat64/az.png)}
.cf-64.cf-bs:before{background-image:url(flat64/bs.png)}
.cf-64.cf-bh:before{background-image:url(flat64/bh.png)}
.cf-64.cf-bd:before{background-image:url(flat64/bd.png)}
.cf-64.cf-bb:before{background-image:url(flat64/bb.png)}
.cf-64.cf-by:before{background-image:url(flat64/by.png)}
.cf-64.cf-be:before{background-image:url(flat64/be.png)}
.cf-64.cf-bz:before{background-image:url(flat64/bz.png)}
.cf-64.cf-bj:before{background-image:url(flat64/bj.png)}
.cf-64.cf-bm:before{background-image:url(flat64/bm.png)}
.cf-64.cf-bt:before{background-image:url(flat64/bt.png)}
.cf-64.cf-bo:before{background-image:url(flat64/bo.png)}
.cf-64.cf-ba:before{background-image:url(flat64/ba.png)}
.cf-64.cf-bw:before{background-image:url(flat64/bw.png)}
.cf-64.cf-br:before{background-image:url(flat64/br.png)}
.cf-64.cf-vg:before{background-image:url(flat64/vg.png)}
.cf-64.cf-bn:before{background-image:url(flat64/bn.png)}
.cf-64.cf-bg:before{background-image:url(flat64/bg.png)}
.cf-64.cf-bf:before{background-image:url(flat64/bf.png)}
.cf-64.cf-bi:before{background-image:url(flat64/bi.png)}
.cf-64.cf-kh:before{background-image:url(flat64/kh.png)}
.cf-64.cf-cm:before{background-image:url(flat64/cm.png)}
.cf-64.cf-ca:before{background-image:url(flat64/ca.png)}
.cf-64.cf-cv:before{background-image:url(flat64/cv.png)}
.cf-64.cf-ky:before{background-image:url(flat64/ky.png)}
.cf-64.cf-cf:before{background-image:url(flat64/cf.png)}
.cf-64.cf-td:before{background-image:url(flat64/td.png)}
.cf-64.cf-cl:before{background-image:url(flat64/cl.png)}
.cf-64.cf-cn:before{background-image:url(flat64/cn.png)}
.cf-64.cf-cx:before{background-image:url(flat64/cx.png)}
.cf-64.cf-co:before{background-image:url(flat64/co.png)}
.cf-64.cf-km:before{background-image:url(flat64/km.png)}
.cf-64.cf-ck:before{background-image:url(flat64/ck.png)}
.cf-64.cf-cr:before{background-image:url(flat64/cr.png)}
.cf-64.cf-hr:before{background-image:url(flat64/hr.png)}
.cf-64.cf-cu:before{background-image:url(flat64/cu.png)}
.cf-64.cf-cy:before{background-image:url(flat64/cy.png)}
.cf-64.cf-cz:before{background-image:url(flat64/cz.png)}
.cf-64.cf-cd:before{background-image:url(flat64/cd.png)}
.cf-64.cf-dk:before{background-image:url(flat64/dk.png)}
.cf-64.cf-dj:before{background-image:url(flat64/dj.png)}
.cf-64.cf-dm:before{background-image:url(flat64/dm.png)}
.cf-64.cf-do:before{background-image:url(flat64/do.png)}
.cf-64.cf-ec:before{background-image:url(flat64/ec.png)}
.cf-64.cf-eg:before{background-image:url(flat64/eg.png)}
.cf-64.cf-sv:before{background-image:url(flat64/sv.png)}
.cf-64.cf-gq:before{background-image:url(flat64/gq.png)}
.cf-64.cf-er:before{background-image:url(flat64/er.png)}
.cf-64.cf-ee:before{background-image:url(flat64/ee.png)}
.cf-64.cf-et:before{background-image:url(flat64/et.png)}
.cf-64.cf-fk:before{background-image:url(flat64/fk.png)}
.cf-64.cf-fj:before{background-image:url(flat64/fj.png)}
.cf-64.cf-fi:before{background-image:url(flat64/fi.png)}
.cf-64.cf-fr:before{background-image:url(flat64/fr.png)}
.cf-64.cf-pf:before{background-image:url(flat64/pf.png)}
.cf-64.cf-ga:before{background-image:url(flat64/ga.png)}
.cf-64.cf-gm:before{background-image:url(flat64/gm.png)}
.cf-64.cf-ge:before{background-image:url(flat64/ge.png)}
.cf-64.cf-de:before{background-image:url(flat64/de.png)}
.cf-64.cf-gh:before{background-image:url(flat64/gh.png)}
.cf-64.cf-gi:before{background-image:url(flat64/gi.png)}
.cf-64.cf-gr:before{background-image:url(flat64/gr.png)}
.cf-64.cf-gl:before{background-image:url(flat64/gl.png)}
.cf-64.cf-gd:before{background-image:url(flat64/gd.png)}
.cf-64.cf-gu:before{background-image:url(flat64/gu.png)}
.cf-64.cf-gt:before{background-image:url(flat64/gt.png)}
.cf-64.cf-gg:before{background-image:url(flat64/gg.png)}
.cf-64.cf-gn:before{background-image:url(flat64/gn.png)}
.cf-64.cf-gy:before{background-image:url(flat64/gy.png)}
.cf-64.cf-ht:before{background-image:url(flat64/ht.png)}
.cf-64.cf-hn:before{background-image:url(flat64/hn.png)}
.cf-64.cf-hk:before{background-image:url(flat64/hk.png)}
.cf-64.cf-hu:before{background-image:url(flat64/hu.png)}
.cf-64.cf-is:before{background-image:url(flat64/is.png)}
.cf-64.cf-in:before{background-image:url(flat64/in.png)}
.cf-64.cf-id:before{background-image:url(flat64/id.png)}
.cf-64.cf-ir:before{background-image:url(flat64/ir.png)}
.cf-64.cf-iq:before{background-image:url(flat64/iq.png)}
.cf-64.cf-ie:before{background-image:url(flat64/ie.png)}
.cf-64.cf-im:before{background-image:url(flat64/im.png)}
.cf-64.cf-il:before{background-image:url(flat64/il.png)}
.cf-64.cf-it:before{background-image:url(flat64/it.png)}
.cf-64.cf-jm:before{background-image:url(flat64/jm.png)}
.cf-64.cf-jp:before{background-image:url(flat64/jp.png)}
.cf-64.cf-je:before{background-image:url(flat64/je.png)}
.cf-64.cf-jo:before{background-image:url(flat64/jo.png)}
.cf-64.cf-kz:before{background-image:url(flat64/kz.png)}
.cf-64.cf-ke:before{background-image:url(flat64/ke.png)}
.cf-64.cf-ki:before{background-image:url(flat64/ki.png)}
.cf-64.cf-xk:before{background-image:url(flat64/xk.png)}
.cf-64.cf-kw:before{background-image:url(flat64/kw.png)}
.cf-64.cf-kg:before{background-image:url(flat64/kg.png)}
.cf-64.cf-la:before{background-image:url(flat64/la.png)}
.cf-64.cf-lv:before{background-image:url(flat64/lv.png)}
.cf-64.cf-lb:before{background-image:url(flat64/lb.png)}
.cf-64.cf-ls:before{background-image:url(flat64/ls.png)}
.cf-64.cf-lr:before{background-image:url(flat64/lr.png)}
.cf-64.cf-ly:before{background-image:url(flat64/ly.png)}
.cf-64.cf-li:before{background-image:url(flat64/li.png)}
.cf-64.cf-lt:before{background-image:url(flat64/lt.png)}
.cf-64.cf-lu:before{background-image:url(flat64/lu.png)}
.cf-64.cf-mo:before{background-image:url(flat64/mo.png)}
.cf-64.cf-mk:before{background-image:url(flat64/mk.png)}
.cf-64.cf-mg:before{background-image:url(flat64/mg.png)}
.cf-64.cf-mw:before{background-image:url(flat64/mw.png)}
.cf-64.cf-my:before{background-image:url(flat64/my.png)}
.cf-64.cf-mv:before{background-image:url(flat64/mv.png)}
.cf-64.cf-ml:before{background-image:url(flat64/ml.png)}
.cf-64.cf-mt:before{background-image:url(flat64/mt.png)}
.cf-64.cf-mh:before{background-image:url(flat64/mh.png)}
.cf-64.cf-mq:before{background-image:url(flat64/mq.png)}
.cf-64.cf-mr:before{background-image:url(flat64/mr.png)}
.cf-64.cf-mu:before{background-image:url(flat64/mu.png)}
.cf-64.cf-yt:before{background-image:url(flat64/yt.png)}
.cf-64.cf-mx:before{background-image:url(flat64/mx.png)}
.cf-64.cf-fm:before{background-image:url(flat64/fm.png)}
.cf-64.cf-md:before{background-image:url(flat64/md.png)}
.cf-64.cf-mc:before{background-image:url(flat64/mc.png)}
.cf-64.cf-mn:before{background-image:url(flat64/mn.png)}
.cf-64.cf-me:before{background-image:url(flat64/me.png)}
.cf-64.cf-ms:before{background-image:url(flat64/ms.png)}
.cf-64.cf-ma:before{background-image:url(flat64/ma.png)}
.cf-64.cf-mz:before{background-image:url(flat64/mz.png)}
.cf-64.cf-mm:before{background-image:url(flat64/mm.png)}
.cf-64.cf-na:before{background-image:url(flat64/na.png)}
.cf-64.cf-nr:before{background-image:url(flat64/nr.png)}
.cf-64.cf-np:before{background-image:url(flat64/np.png)}
.cf-64.cf-nl:before{background-image:url(flat64/nl.png)}
.cf-64.cf-nc:before{background-image:url(flat64/nc.png)}
.cf-64.cf-nz:before{background-image:url(flat64/nz.png)}
.cf-64.cf-ni:before{background-image:url(flat64/ni.png)}
.cf-64.cf-ne:before{background-image:url(flat64/ne.png)}
.cf-64.cf-ng:before{background-image:url(flat64/ng.png)}
.cf-64.cf-nu:before{background-image:url(flat64/nu.png)}
.cf-64.cf-nf:before{background-image:url(flat64/nf.png)}
.cf-64.cf-kp:before{background-image:url(flat64/kp.png)}
.cf-64.cf-mp:before{background-image:url(flat64/mp.png)}
.cf-64.cf-no:before{background-image:url(flat64/no.png)}
.cf-64.cf-om:before{background-image:url(flat64/om.png)}
.cf-64.cf-pk:before{background-image:url(flat64/pk.png)}
.cf-64.cf-pw:before{background-image:url(flat64/pw.png)}
.cf-64.cf-ps:before{background-image:url(flat64/ps.png)}
.cf-64.cf-pa:before{background-image:url(flat64/pa.png)}
.cf-64.cf-pg:before{background-image:url(flat64/pg.png)}
.cf-64.cf-py:before{background-image:url(flat64/py.png)}
.cf-64.cf-pe:before{background-image:url(flat64/pe.png)}
.cf-64.cf-ph:before{background-image:url(flat64/ph.png)}
.cf-64.cf-pn:before{background-image:url(flat64/pn.png)}
.cf-64.cf-pl:before{background-image:url(flat64/pl.png)}
.cf-64.cf-pt:before{background-image:url(flat64/pt.png)}
.cf-64.cf-pr:before{background-image:url(flat64/pr.png)}
.cf-64.cf-qa:before{background-image:url(flat64/qa.png)}
.cf-64.cf-cg:before{background-image:url(flat64/cg.png)}
.cf-64.cf-ro:before{background-image:url(flat64/ro.png)}
.cf-64.cf-ru:before{background-image:url(flat64/ru.png)}
.cf-64.cf-rw:before{background-image:url(flat64/rw.png)}
.cf-64.cf-kn:before{background-image:url(flat64/kn.png)}
.cf-64.cf-lc:before{background-image:url(flat64/lc.png)}
.cf-64.cf-mf:before{background-image:url(flat64/mf.png)}
.cf-64.cf-vc:before{background-image:url(flat64/vc.png)}
.cf-64.cf-ws:before{background-image:url(flat64/ws.png)}
.cf-64.cf-sm:before{background-image:url(flat64/sm.png)}
.cf-64.cf-sa:before{background-image:url(flat64/sa.png)}
.cf-64.cf-sn:before{background-image:url(flat64/sn.png)}
.cf-64.cf-rs:before{background-image:url(flat64/rs.png)}
.cf-64.cf-sc:before{background-image:url(flat64/sc.png)}
.cf-64.cf-sl:before{background-image:url(flat64/sl.png)}
.cf-64.cf-sg:before{background-image:url(flat64/sg.png)}
.cf-64.cf-sk:before{background-image:url(flat64/sk.png)}
.cf-64.cf-si:before{background-image:url(flat64/si.png)}
.cf-64.cf-sb:before{background-image:url(flat64/sb.png)}
.cf-64.cf-so:before{background-image:url(flat64/so.png)}
.cf-64.cf-za:before{background-image:url(flat64/za.png)}
.cf-64.cf-gs:before{background-image:url(flat64/gs.png)}
.cf-64.cf-kr:before{background-image:url(flat64/kr.png)}
.cf-64.cf-ss:before{background-image:url(flat64/ss.png)}
.cf-64.cf-es:before{background-image:url(flat64/es.png)}
.cf-64.cf-lk:before{background-image:url(flat64/lk.png)}
.cf-64.cf-sd:before{background-image:url(flat64/sd.png)}
.cf-64.cf-sr:before{background-image:url(flat64/sr.png)}
.cf-64.cf-sz:before{background-image:url(flat64/sz.png)}
.cf-64.cf-se:before{background-image:url(flat64/se.png)}
.cf-64.cf-ch:before{background-image:url(flat64/ch.png)}
.cf-64.cf-sy:before{background-image:url(flat64/sy.png)}
.cf-64.cf-tw:before{background-image:url(flat64/tw.png)}
.cf-64.cf-tj:before{background-image:url(flat64/tj.png)}
.cf-64.cf-tz:before{background-image:url(flat64/tz.png)}
.cf-64.cf-th:before{background-image:url(flat64/th.png)}
.cf-64.cf-tg:before{background-image:url(flat64/tg.png)}
.cf-64.cf-tk:before{background-image:url(flat64/tk.png)}
.cf-64.cf-to:before{background-image:url(flat64/to.png)}
.cf-64.cf-tt:before{background-image:url(flat64/tt.png)}
.cf-64.cf-tn:before{background-image:url(flat64/tn.png)}
.cf-64.cf-tr:before{background-image:url(flat64/tr.png)}
.cf-64.cf-tm:before{background-image:url(flat64/tm.png)}
.cf-64.cf-tc:before{background-image:url(flat64/tc.png)}
.cf-64.cf-tv:before{background-image:url(flat64/tv.png)}
.cf-64.cf-ug:before{background-image:url(flat64/ug.png)}
.cf-64.cf-ua:before{background-image:url(flat64/ua.png)}
.cf-64.cf-ae:before{background-image:url(flat64/ae.png)}
.cf-64.cf-gb:before{background-image:url(flat64/gb.png)}
.cf-64.cf-us:before{background-image:url(flat64/us.png)}
.cf-64.cf-uy:before{background-image:url(flat64/uy.png)}
.cf-64.cf-uz:before{background-image:url(flat64/uz.png)}
.cf-64.cf-vu:before{background-image:url(flat64/vu.png)}
.cf-64.cf-va:before{background-image:url(flat64/va.png)}
.cf-64.cf-ve:before{background-image:url(flat64/ve.png)}
.cf-64.cf-vn:before{background-image:url(flat64/vn.png)}
.cf-64.cf-wf:before{background-image:url(flat64/wf.png)}
.cf-64.cf-eh:before{background-image:url(flat64/eh.png)}
.cf-64.cf-ye:before{background-image:url(flat64/ye.png)}
.cf-64.cf-zm:before{background-image:url(flat64/zm.png)}
.cf-64.cf-zw:before{background-image:url(flat64/zw.png)}